import Helper from '@ember/component/helper';
import { service } from '@ember/service';

import { selectedCategoryList } from 'qonto/utils/cards/display-card-selected-categories';

export default class DisplayCardSelectedCategories extends Helper {
  @service intl;

  compute([categoryTags]) {
    return selectedCategoryList(categoryTags, this.intl);
  }
}
