import { waitForPromise } from '@ember/test-waiters';

import { isTesting, macroCondition } from '@embroider/macros';
import { transition } from 'd3-transition';

export const SPEEDUP = Symbol('instantly finish D3 transitions');
export function waitForD3Transition() {
  if (macroCondition(isTesting())) {
    if (transition.prototype[SPEEDUP]) {
      this.delay(0);
      this.duration(0);
    }
    waitForPromise(this.end());
  }
  return this;
}

export function initialize() {
  if (!transition.prototype.waitFor) {
    transition.prototype.waitFor = waitForD3Transition;
  }
}

export default {
  initialize,
};
