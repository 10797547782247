import type { ParsingOptions, WorkBook, WorkSheet, XLSX$Utils } from 'xlsx';

type XlsxLib = {
  read: (data: any, opts?: ParsingOptions) => WorkBook;
  utils: XLSX$Utils;
};

export class XlsFile {
  static xlsx: XlsxLib;

  #workbook: WorkBook | null = null;
  #rows: Record<string, string>[] = [];
  #columns: string[] = [];

  static async parse(file: File) {
    let xlsxFile = new XlsFile();

    if (!XlsFile.xlsx) {
      XlsFile.xlsx = await import('xlsx');
    }

    await xlsxFile.#extractWorkbook(file);
    xlsxFile.#extractData();
    xlsxFile.#extractColumns();

    return xlsxFile;
  }

  get rows() {
    return this.#rows;
  }

  get columns() {
    return this.#columns;
  }

  removeColumns(columnsToRemove: string[]) {
    this.#columns = this.#columns?.filter(column => !columnsToRemove.includes(column));
  }

  sortColumns(sortFn: (a: string, b: string) => number) {
    this.#columns.sort(sortFn);
  }

  async #extractWorkbook(file: File) {
    if (file.type.match(/text\/.*/i)) {
      this.#workbook = XlsFile.xlsx.read(await file.text(), { type: 'string' });
    } else {
      this.#workbook = XlsFile.xlsx.read(await file.arrayBuffer(), { type: 'buffer' });
    }
  }

  #extractData() {
    let sheetName = this.#workbook?.SheetNames[0];
    if (sheetName) {
      let sheet = this.#workbook?.Sheets[sheetName];
      if (sheet) {
        if (this.#hasHeader(sheet)) {
          this.#rows = XlsFile.xlsx.utils.sheet_to_json(sheet, { defval: null });
        } else {
          this.#rows = XlsFile.xlsx.utils.sheet_to_json(sheet, { header: 'A', defval: null });
        }
      }
    }
  }

  #extractColumns() {
    if (this.#rows[0]) {
      this.#columns = Object.keys(this.#rows[0]);
    }
  }

  #hasHeader(sheet: WorkSheet) {
    let json = XlsFile.xlsx.utils.sheet_to_json<string[]>(sheet, { header: 1 });
    return json?.[0] && isHeader(json[0]);
  }
}

function isHeader(row: string[]) {
  return (
    row.every(cell => cell !== null && typeof cell === 'string' && !isDate(cell)) &&
    new Set(row).size === row.length
  );
}

function isDate(str: any) {
  return isNaN(str) && !isNaN(Date.parse(str));
}
