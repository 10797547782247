/* eslint-disable @qonto/use-safe-local-storage, no-console */
import window from 'ember-window-mock';

export let safeLocalStorage = {
  setItem(key: string, value: string) {
    try {
      window.localStorage.setItem(key, value);
      return true;
    } catch (error) {
      console.error('Error setting item in localStorage:', error);
      return false;
    }
  },

  getItem(key: string) {
    try {
      return window.localStorage.getItem(key);
    } catch (error) {
      console.error('Error retrieving item from localStorage:', error);
      return null;
    }
  },

  removeItem(key: string) {
    try {
      window.localStorage.removeItem(key);
      return true;
    } catch (error) {
      console.error('Error removing item from localStorage:', error);
      return false;
    }
  },
};

declare module 'qonto/helpers/safe-local-storage' {
  export default interface safeLocalStorage {
    getItem(key: string): string | null;
    setItem(key: string, value: string): void;
    removeItem(key: string): void;
  }
}
