import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class FormatDateRelativeHelper extends Helper {
  @service intl;

  constructor() {
    super(...arguments);
    this.intl.onLocaleChanged(this.recompute, this);
  }

  compute([value], { format }) {
    return this.intl.formatDateRelative(value, format);
  }
}
