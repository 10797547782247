import Helper from '@ember/component/helper';
import { service } from '@ember/service';

/**
 * This helper can be used from the templates to send tracking events to
 * the `segment` service.
 *
 * ```hbs
 * <button {{on "click" (track "foo" bar=42)}}/>
 * ```
 */

export default class TrackHelper extends Helper {
  @service segment;

  compute([event], params) {
    return () => {
      this.segment.track(event, params);
    };
  }
}
