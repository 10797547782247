import { helper } from '@ember/component/helper';

export default helper(function localizeUrl([url, locale]) {
  if (!url) {
    return;
  }

  if (!locale) {
    return url;
  }

  let hasQuery = url.includes('?');
  if (hasQuery) {
    return `${url}&locale=${locale}`;
  } else {
    return `${url}?locale=${locale}`;
  }
});
