import { helper } from '@ember/component/helper';

/**
 * This helper can be used to compute the `aria-sort` attribute of each `<th>` of the paginated tables used for sorting.
 * It must NOT be applied to the `<th>` of a column not concern by a sort.
 *
 * In this case, the only expected return values are:
 * - `ascending`
 * - `descending`
 * - `none`
 *
 * @param {string[]} params An array containing 3 parameters: the field for which we want to compute the aria-sort attribute, the active field on which the sort will be applied, and the current order used when querying (`asc` or `desc`)
 * @returns {string} A string representing the order currently applied to the table field (`ascending`, `descending` or `none`)
 */
export function ariaSort([currentField, activeField, currentOrder]) {
  let isCurrentFieldActive = currentField === activeField;
  let formattedOrder = currentOrder === 'asc' ? 'ascending' : 'descending';

  return isCurrentFieldActive ? formattedOrder : 'none';
}

export default helper(ariaSort);
