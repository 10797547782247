import { service } from '@ember/service';

import FormatNumberHelper from 'ember-intl/helpers/format-number';

const defaultOptions = {
  minimumFractionDigits: 0,
  currency: 'EUR',
  style: 'currency',
};

export default class extends FormatNumberHelper {
  @service intl;

  compute(value, options) {
    return this.intl.formatNumber(value, { ...defaultOptions, ...options });
  }
}
