import { helper } from '@ember/component/helper';

import { importSync } from '@embroider/macros';

export function importAsset([asset]: [string]): string {
  const module = importSync(`/static/${asset.replace(/^\//, '')}`) as { default: string };
  return module.default;
}

export default helper(importAsset);
