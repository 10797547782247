export const SmartPinOnBehalfDelivery = {
  _AESKey: null,
  _EncryptedAESKey: '',
  _ServerKey: null,
  _cryptoApi: function () {
    var crypto = window.crypto || window.msCrypto;
    return crypto.subtle || crypto.webkitSubtle;
  },
  _crypto: function () {
    var crypto = window.crypto || window.msCrypto;
    return crypto;
  },
  _SessionId: '',
  TimeToReadPIN: 30,
  _URL: '',

  GetPIN: function (
    URL,
    CardholderIdentifier,
    RequestReference,
    IssuerReference,
    ProviderID,
    Base64Signature,
    ServiceType,
    Language,
    TIME,
    OnErrorFunction,
    OnCompleteFunction
  ) {
    SmartPinOnBehalfDelivery._URL = URL;
    let data =
      '{"token" : { "CardholderIdentifier": "' +
      CardholderIdentifier +
      '", "RequestReference": "' +
      RequestReference +
      '", "IssuerReference": "' +
      IssuerReference +
      '", "ProviderID": "' +
      ProviderID +
      '", "Base64Signature": "' +
      Base64Signature +
      '", "Language": "' +
      Language +
      '", "ServiceType": "' +
      ServiceType +
      '", "TIME": "' +
      TIME +
      '" }}';
    //Call the first WS
    fetch(URL + '/InitPINDelivery', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: data,
    })
      .then(response => response.json())
      .then(function (msg) {
        if (msg.d.ErrorCode != 0) {
          OnErrorFunction(msg.d.ErrorCode);
        } else {
          SmartPinOnBehalfDelivery.TimeToReadPIN = msg.d.TimeToReadPIN;
          SmartPinOnBehalfDelivery._SessionId = msg.d.SessionId;
          SmartPinOnBehalfDelivery._InitKey(
            msg.d.PublicKeyPEM,
            'SHA-256',
            function () {
              OnErrorFunction(111);
            },
            function () {
              SmartPinOnBehalfDelivery._CallGetPIN(OnErrorFunction, OnCompleteFunction);
            }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        OnErrorFunction(106);
      });
  },

  PINDisplayed: function () {
    fetch(SmartPinOnBehalfDelivery._URL + '/PINWasDisplayed', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      credentials: 'omit',
      body: '{ "SessionId" : "' + SmartPinOnBehalfDelivery._SessionId + '" }',
    });
  },

  _AckDecryption: function (success) {
    fetch(SmartPinOnBehalfDelivery._URL + '/AckDecryption', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      credentials: 'omit',
      body:
        '{ "SessionId" : "' +
        SmartPinOnBehalfDelivery._SessionId +
        '", "Success" : "' +
        success +
        '" }',
    });
  },

  _CallGetPIN: function (OnErrorFunction, OnCompleteFunction) {
    fetch(SmartPinOnBehalfDelivery._URL + '/GetPINWithKey', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      credentials: 'omit',
      body:
        '{ "Base64EncryptedSessionKey": "' +
        SmartPinOnBehalfDelivery._GetEncryptedKey() +
        '", "SessionId" : "' +
        SmartPinOnBehalfDelivery._SessionId +
        '" }',
    })
      .then(response => response.json())
      .then(function (msg) {
        if (msg.d.ErrorCode != 0) {
          OnErrorFunction(msg.d.ErrorCode);
        } else {
          SmartPinOnBehalfDelivery._DecryptPIN(
            msg.d.Base64EncryptedPIN,
            msg.d.Base64IV,
            function (PIN) {
              SmartPinOnBehalfDelivery._AckDecryption(true);
              OnCompleteFunction(PIN);
            },
            function (error) {
              SmartPinOnBehalfDelivery._AckDecryption(false);
              OnErrorFunction(106);
            }
          );
        }
      })
      .catch(function (error) {
        OnErrorFunction(106);
      });
  },

  _InitKey: function (RSAKeyPEM, hash, ErrorFunction, OnCompleteFunction) {
    //Generate Key pair
    this._cryptoApi()
      .generateKey(
        {
          name: 'AES-CBC',
          length: 128, //can be  128, 192, or 256
        },
        true, //whether the key is extractable (i.e. can be used in exportKey)
        ['encrypt', 'decrypt'] //can be "encrypt", "decrypt", "wrapKey", or "unwrapKey"
      )
      .then(function (key) {
        SmartPinOnBehalfDelivery._AESKey = key;
        SmartPinOnBehalfDelivery._pemPublicToCrypto(
          RSAKeyPEM,
          hash,
          //success
          function (RSAKey) {
            SmartPinOnBehalfDelivery._ServerKey = RSAKey;

            SmartPinOnBehalfDelivery._cryptoApi()
              .wrapKey(
                'raw',
                SmartPinOnBehalfDelivery._AESKey,
                SmartPinOnBehalfDelivery._ServerKey,
                {
                  name: 'RSA-OAEP',
                  hash: { name: hash },
                }
              )
              .then(function (encryptedSharedKey) {
                SmartPinOnBehalfDelivery._EncryptedAESKey =
                  SmartPinHelper.arrayBufferToBase64(encryptedSharedKey);
                //Completed
                if (OnCompleteFunction !== undefined) {
                  OnCompleteFunction();
                }
              })
              .catch(function (err) {
                if (ErrorFunction !== undefined) {
                  ErrorFunction();
                }
              });
          },
          function (err) {
            //console.log(err);
            if (ErrorFunction !== undefined) {
              ErrorFunction();
            }
          }
        );
      })
      .catch(function (err) {
        if (ErrorFunction !== undefined) {
          ErrorFunction();
        }
      });
  },

  _GetEncryptedKey: function () {
    return this._EncryptedAESKey;
  },

  _DecryptPIN: function (data, IV, callback, errorCallback) {
    //console.log(data, IV);
    this._cryptoApi()
      .decrypt(
        {
          name: 'AES-CBC',
          iv: SmartPinHelper.base64ToArrayBuffer(IV),
        },
        SmartPinOnBehalfDelivery._AESKey,
        SmartPinHelper.base64ToArrayBuffer(data)
      )
      .then(function (decrypted) {
        var PINBlock = SmartPinHelper.buf2hex(decrypted);
        if (!SmartPinHelper.IsPinBlockOk(PINBlock)) {
          errorCallback('Error: could not decrypt PIN');
          return;
        }
        callback(SmartPinHelper.PinBlockToPin(PINBlock));
      })
      .catch(function (err) {
        errorCallback(err);
      });
  },

  _pemPublicToCrypto: function (pem, hash, callback, error) {
    var self = this;
    pem = SmartPinHelper.removeLines(pem);
    pem = pem.replace('-----BEGIN PUBLIC KEY-----', '');
    var b64 = pem.replace('-----END PUBLIC KEY-----', '');
    var arrayBuffer = SmartPinHelper.base64ToArrayBuffer(b64);

    var opt = {
      name: 'RSA-OAEP',
      hash: { name: hash },
    };

    var usages = ['wrapKey', 'encrypt'];

    self
      ._cryptoApi()
      .importKey('spki', arrayBuffer, opt, true, usages)
      .then(function (importedKey) {
        callback(importedKey);
      })
      .catch(function (err) {
        error(err);
      });
  },
};

export const SmartPinHelper = {
  removeLines: function (str) {
    return str.replace(/\r?\n|\r/g, '');
  },
  addNewLines: function (str) {
    var finalString = '';
    while (str.length > 0) {
      finalString += str.substring(0, 64) + '\r\n';
      str = str.substring(64);
    }

    return finalString;
  },

  buf2hex: function (buffer) {
    // buffer is an ArrayBuffer
    return Array.prototype.map
      .call(new Uint8Array(buffer), function (x) {
        return ('00' + x.toString(16)).slice(-2);
      })
      .join('');
  },

  _chars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/',
  arrayBufferToBase64: function (arrayBuffer) {
    if (typeof arrayBuffer !== 'object') {
      throw new TypeError('Expected input to be an ArrayBuffer Object');
    }
    return this._encodeAb(arrayBuffer);
  },

  _encodeAb: function (arrayBuffer) {
    var bytes = new Uint8Array(arrayBuffer);
    var len = bytes.length;
    var base64 = '';

    for (let i = 0; i < len; i += 3) {
      base64 += this._chars[bytes[i] >> 2];
      base64 += this._chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
      base64 += this._chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
      base64 += this._chars[bytes[i + 2] & 63];
    }

    if (len % 3 === 2) {
      base64 = base64.substring(0, base64.length - 1) + '=';
    } else if (len % 3 === 1) {
      base64 = base64.substring(0, base64.length - 2) + '==';
    }

    return base64;
  },

  base64ToArrayBuffer: function (b64) {
    return this._decodeAb(b64);
  },

  _decodeAb: function (base64) {
    var lookup = new Uint8Array(256);
    for (let i = 0; i < this._chars.length; i++) {
      lookup[this._chars.charCodeAt(i)] = i;
    }

    var bufferLength = base64.length * 0.75;
    var len = base64.length;
    var p = 0;
    var encoded1;
    var encoded2;
    var encoded3;
    var encoded4;

    if (base64[base64.length - 1] === '=') {
      bufferLength--;
      if (base64[base64.length - 2] === '=') {
        bufferLength--;
      }
    }

    var arrayBuffer = new ArrayBuffer(bufferLength);
    var bytes = new Uint8Array(arrayBuffer);

    for (let i = 0; i < len; i += 4) {
      encoded1 = lookup[base64.charCodeAt(i)];
      encoded2 = lookup[base64.charCodeAt(i + 1)];
      encoded3 = lookup[base64.charCodeAt(i + 2)];
      encoded4 = lookup[base64.charCodeAt(i + 3)];

      bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
      bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
      bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
    }

    return arrayBuffer;
  },

  PinBlockToPin: function (PinBlock) {
    return PinBlock.substring(2, 2 + parseInt(PinBlock[1], 16));
  },
  IsPinBlockOk: function (PinBlock) {
    //Check first char
    if (PinBlock[0] != '1') {
      return false;
    }
    //Check length
    var theLength = parseInt(PinBlock[1], 16);
    if (isNaN(theLength) || theLength > 14) {
      return false;
    }
    //Check data
    for (var i = 0; i < theLength; i++) {
      var value = parseInt(PinBlock[i + 2], 16);
      if (isNaN(value) || value > 9) {
        return false;
      }
    }
    return true;
  },
  str2ab: function (str) {
    var buf = new ArrayBuffer(str.length); // 2 bytes for each char
    var bufView = new Uint8Array(buf);
    for (var i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  },
};
