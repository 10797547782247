import { service } from '@ember/service';

import FormatNumberHelper from 'ember-intl/helpers/format-number';

const defaultOptions = {
  style: 'percent',
  minimumFractionDigits: 3,
};

export default class extends FormatNumberHelper {
  @service intl;

  compute(value, options) {
    return this.intl.formatNumber(value / 100, { ...defaultOptions, ...options });
  }
}
