import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class performSensitiveTask extends Helper {
  @service sensitiveActions;

  compute([sensitiveTask, ...positionalArguments]) {
    return (...additionalArgs) =>
      this.sensitiveActions.runTask.perform(
        sensitiveTask,
        ...positionalArguments,
        ...additionalArgs
      );
  }
}
