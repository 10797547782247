import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class extends Helper {
  @service intl;

  compute([value]) {
    if (value) {
      return value;
    }
    return this.intl.t('transactions.counterparty.unknown');
  }
}
