import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class TErrorHelper extends Helper {
  @service errors;
  @service intl;

  compute([error]) {
    return this.errors.messageForStatus(error);
  }
}
