import Helper from '@ember/component/helper';
import { service } from '@ember/service';

import { CENTS_MULTIPLIER } from 'qonto/constants/transfers';

const defaultOptions = {
  minimumFractionDigits: 2,
};

export default class FormatMoneyHelper extends Helper {
  @service intl;

  init() {
    super.init(...arguments);
    this.intl.onLocaleChanged(this.recompute, this);
  }

  compute(params, options) {
    let [value] = params;
    let hash = Object.assign({}, defaultOptions, options);
    let centMultiplier = CENTS_MULTIPLIER[hash.currency];
    if (centMultiplier === 1) {
      hash.minimumFractionDigits = options.minimumFractionDigits || 0;
      hash.maximumFractionDigits = options.maximumFractionDigits || hash.minimumFractionDigits;
    }

    if (hash.displayUndefined) {
      return Number.isFinite(value) ? this.intl.formatMoney(value, hash) : '--';
    } else {
      return this.intl.formatMoney(value, hash);
    }
  }
}
