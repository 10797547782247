import Helper from '@ember/component/helper';
import { service } from '@ember/service';

import maybeT from 'qonto/utils/maybe-t';

export default class MaybeTHelper extends Helper {
  @service intl;
  @service localeManager;

  /**
   * Computes and returns the translated text based on a key or the fallback text.
   *
   * @param {Array} args - An array containing the translation key and the fallback text.
   * @param {string} args[0] - The translation key.
   * @param {string} args[1] - The fallback text.
   * @returns {string} The translated text based on the key or the fallback text if the translation does not exist.
   */
  compute([translationKey, translationFallback]) {
    return maybeT(
      { key: translationKey, fallback: translationFallback },
      this.intl,
      this.localeManager.locale
    );
  }
}
