import Helper from '@ember/component/helper';
import { service } from '@ember/service';

import window from 'ember-window-mock';

export default class TrackButtonHelper extends Helper {
  @service segment;

  compute([event]) {
    return () => {
      this.segment.track('navigation_button_clicked', {
        button_name: event,
        browser_width: `${window.innerWidth}px`,
        browser_height: `${window.innerHeight}px`,
      });
    };
  }
}
