import Helper from '@ember/component/helper';
import { service } from '@ember/service';

import { activeDaysList } from 'qonto/utils/cards/display-card-active-days';

export default class DisplayCardActiveDays extends Helper {
  @service intl;

  compute([activeDays]) {
    return activeDaysList(activeDays, this.intl);
  }
}
