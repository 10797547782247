import Helper from '@ember/component/helper';
import { service } from '@ember/service';

import { getCardShortName } from 'qonto/utils/cards/card-names';

export default class DisplayCardShortName extends Helper {
  @service intl;

  compute([cardLevel]) {
    return getCardShortName(cardLevel, this.intl);
  }
}
